<template>
  <div>
    <b-list-group
      v-b-hover="handleHover"
      class="bg-gray-primary rounded-0 collapsed-menu vh-100 pb-5"
      :class="{ 'expanded-menu': isMenuExpanded }"
    >
      <!-- <b-list-group-item
      class="rounded-0 border-0 clickable mt-2 px-3"
      :class="{ 'mb-4': !isExpandItemVisible }"
      @click="goToDashboard"
    >
      <b-row class="no-gutters text-white flex-nowrap">
        <b-col class="col-auto">
          <img
            width="28px"
            src="./../assets/general-icons/Icon-Emotiva-2020.svg"
            fluid
            alt="logo"
          />
        </b-col>
        <b-col v-if="isMenuExpanded" class="ml-3 h5 mb-0">
          {{ $t("sideMenu.emotiva") }}
        </b-col>
      </b-row>
    </b-list-group-item> -->

      <b-list-group-item
        class="rounded-0 border-0 clickable"
        :class="{ emotiva__pink__background: isRouteActive('Dashboard') }"
        @click="goToPage('Dashboard')"
      >
        <b-row
          data-v-step="11"
          class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
        >
          <b-col class="col-auto">
            <img
              src="./../assets/menu-icons/MENU_home.svg"
              class="menu__icon"
              :class="{ 'active__menu-icon': isRouteActive('Dashboard') }"
            />
          </b-col>
          <b-col
            v-if="isMenuExpanded"
            class="menu__text-item text-hide-overflow"
            :class="{ menu__text__active: isRouteActive('Dashboard') }"
          >
            {{ $t("sideMenu.home") }}
          </b-col>
        </b-row>
      </b-list-group-item>
      <!-- <b-list-group-item
        class="rounded-0 border-0 clickable"
        :class="{ emotiva__pink__background: isRouteActive('Plan') }"
        @click="goToPage('Plan')"
      >
        <b-row
          data-v-step="11"
          class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
        >
          <b-col class="col-auto">
            <img
              src="../assets/menu-icons/MENU_icon-pro.svg"
              class="menu__icon"
              :class="{ 'active__menu-icon': isRouteActive('Plan') }"
            />
          </b-col>
          <b-col
            v-if="isMenuExpanded"
            class="menu__text-item text-hide-overflow"
            :class="{ menu__text__active: isRouteActive('Plan') }"
          >
            {{ $t("sideMenu.plan") }}
          </b-col>
        </b-row>
      </b-list-group-item> -->
      <b-list-group-item
        class="rounded-0 border-0 clickable"
        :class="{ emotiva__pink__background: isRouteActive('Billing-info') }"
        @click="goToPage('Billing-info')"
      >
        <b-row
          data-v-step="11"
          class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
        >
          <b-col class="col-auto">
            <img
              src="./../assets/menu-icons/MENU_credit-card-white.svg"
              class="menu__icon"
              :class="{ 'active__menu-icon': isRouteActive('Billing-info') }"
            />
          </b-col>
          <b-col
            v-if="isMenuExpanded"
            class="menu__text-item text-hide-overflow"
            :class="{ menu__text__active: isRouteActive('Billing-info') }"
          >
            {{ $t("sideMenu.paymentBilling") }}
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item
        class="rounded-0 border-0 clickable"
        @click="openExternalPage(supportLinks)"
      >
        <b-row
          data-v-step="100"
          class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
        >
          <b-col class="col-auto">
            <img
              src="./../assets/menu-icons/MENU_support-faq.svg"
              class="menu__icon"
            />
          </b-col>
          <b-col v-if="isMenuExpanded" class="menu__text-item">
            {{ $t("sideMenu.help") }}
          </b-col>
        </b-row>
      </b-list-group-item>
      <hr />
      <!-- <b-list-group-item
        v-for="(folder, index) in folderList"
        :key="index"
        class="rounded-0 border-0 clickable"
        :class="{ emotiva__pink__background: isFolderRouteActive(folder) }"
        @click="goToFolder('Folder', folder._id)"
      >
        <b-row
          class="no-gutters text-white flex-nowrap align-items-end menu__item-container"
        >
          <b-col class="col-auto">
            <img
              src="./../assets/menu-icons/MENU_folder.svg"
              class="menu__icon"
              style="margin-top: -10px;"
              :class="{ 'active__menu-icon': isFolderRouteActive(folder) }"
            />
          </b-col>
          <b-col
            class="menu__text-item"
            :class="{ menu__text__active: isFolderRouteActive(folder) }"
          >
            <div class="text-hide-overflow">{{ folder.name }}</div>
            <div>
              <b-dropdown
                no-caret
                right
                size="sm"
                variant="none"
                style="background-color: transparent"
              >
                <template #button-content style="background-color: transparent">
                  <img
                    src="../assets/menu-icons/MENU_three_dots.svg"
                    alt=""
                    class="menu__icon"
                  />
                </template>
                <b-dropdown-item @click="openRenameFolderModal(folder)">
                  {{ $t("sideMenu.rename") }}
                </b-dropdown-item>
                <b-dropdown-item @click="openDeleteFolderModal(folder)">
                  {{ $t("sideMenu.delete") }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
      </b-list-group-item> -->


    </b-list-group>
    <generic-simple-input-modal
      :modalStatus="folderRenameModalStatus"
      :inputString="selectedFolder.name"
      :title="$t('sideMenu.renameFolder')"
      :bodyText="$t('name')"
      @setInputString="setNewFolderName"
      @setModalStatus="setFolderRenameModalStatus"
      @submit="renameFolder"
    >
    </generic-simple-input-modal>
    <generic-yes-no-modal
      :modalStatus="folderDeleteModalStatus"
      :inputString="selectedFolder.name"
      :title="$t('sideMenu.deleteFolderTitle')"
      :bodyText="`${$t('sideMenu.deleteFolderText')} ${selectedFolder.name} ?`"
      :warning='$t("sideMenu.deleteFolderWarning")'
      variant="danger"
      @setModalStatus="setFolderDeleteModalStatus"
      @submit="deleteFolder"
    >
    </generic-yes-no-modal>
  </div>
</template>

<script>
import Utils from "./../Utils";
import GenericSimpleInputModal from "./Modals/GenericSimpleInputModal.vue";
import GenericYesNoModal from "./Modals/GenericYesNoModal.vue";
export default {
  created() {},
  components: {
    GenericSimpleInputModal,
    GenericYesNoModal,
  },
  data() {
    return {
      isHovered: false,
      isExpandItemActive: false,
      isExpandItemVisible: false,
      supportLinks: {
        linkEn: "https://emotiva.it/en/foresight-metrics/",
        linkIt: "https://emotiva.it/en/foresight-metrics/",
      },
      folderRenameModalStatus: false,
      folderDeleteModalStatus: false,
      selectedFolder: {},
      newFolderName: "",
    };
  },
  watch: {
    isExpandItemVisible: {
      handler: function () {
        // Close menu on window resize
        if (this.isExpandItemVisible) this.isExpandItemActive = false;
        else this.isHovered = false;
      },
      immediate: false,
    },
  },
  computed: {
    isTourActive() {
      // return this.$store.getters["settings/isTourActive"];
      return false;
    },

    isMenuExpanded() {
      if (this.isTourActive) return true;

      return this.isExpandItemVisible
        ? this.isExpandItemActive
        : this.isHovered;
    },
    isClientCustom() {
      return (
        this.$store.getters["auth/getUserType"] === Utils.USER_TYPE.CUSTOMER
      );
    },
    folderList() {
      const folderList = this.$store.getters["folder/getFolderList"];
      const sortedFolderList = folderList.slice().sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      return sortedFolderList;
    },
    isAllowedAccountRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      if (userType === "custom" && isMultiUser) {
        // allow only admins to access account route
        if (userSubType === "admin") {
          return true;
        } else {
          return false;
        }
      }
      return true;
    },
    isAllowedBranchesRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      // only custom + multiuser clients can access route
      if (userType === "custom" && isMultiUser) {
        // allow only admins to access branches route
        if (userSubType === "admin") {
          return true;
        } else {
          return false;
        }
      }

      // return false for all other client types
      return false;
    },
    isAllowedUsersRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      // only custom + multiuser clients can access route
      if (userType === "custom" && isMultiUser) {
        // allow everyone except researchers to access route
        if (
          userSubType === "admin" ||
          userSubType === "branch" ||
          userSubType === "collaborator"
        ) {
          return true;
        } else {
          return false;
        }
      }

      // return false for all other client types
      return false;
    },
    isAllowedBillingRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      // only custom clients can access route
      if (userType === "custom") {
        // prevent access to branch, collaborator, researcher subType
        if (
          isMultiUser &&
          (userSubType === "branch" ||
            userSubType === "collaborator" ||
            userSubType === "researcher")
        ) {
          return false;
        }
        // all other custom clients can access route
        return true;
      }
      // return false for all other client types
      return false;
    },
    isAllowedUsageRoute() {
      const isMultiUser = this.$store.getters["auth/isMultiUser"];
      const userType = this.$store.getters["auth/getUserType"];
      const userSubType = this.$store.getters["auth/getUserSubType"];

      // only custom clients can access route
      if (userType === "custom") {
        // prevent access to branch, collaborator, researcher subType
        if (
          isMultiUser &&
          (userSubType === "branch" ||
            userSubType === "collaborator" ||
            userSubType === "researcher")
        ) {
          return false;
        }
        // all other custom clients can access route
        return true;
      }
      // return false for all other client types
      return false;
    },
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    handleExpandItemVisibility(val) {
      this.isExpandItemVisible = val;
    },
    setFolderRenameModalStatus(status) {
      this.folderRenameModalStatus = status;
    },
    setFolderDeleteModalStatus(status) {
      this.folderDeleteModalStatus = status;
    },
    goToDashboard() {
      if (this.$route.name !== "Dashboard" && this.$route.name !== "Login") {
        this.isHovered = false;
        this.isExpandItemActive = false;
        this.$router.push({ name: "Dashboard" });
      }
    },
    goToPage(routeName) {
      if (this.$route.name !== routeName) {
        this.isHovered = false;
        this.isExpandItemActive = false;
        this.$router.push({ name: routeName });
      }
    },
    goToFolder(routeName, folderId) {
      if (
        this.$route.name !== routeName ||
        this.$route.params.id !== folderId
      ) {
        this.isHovered = false;
        this.isExpandItemActive = false;
        this.$router.push({ name: routeName, params: { id: folderId } });
        // parameter changes not the route, beforeEnter won't trigger
        this.$store
          .dispatch("folder/retrieveFolderByClientId", folderId)
          .then(() => {})
          .catch((isAuthorizationError) => {
            if (isAuthorizationError) this.$store.dispatch("loader/close");
          })
          .finally(this.$store.dispatch("loader/close"));
      }
    },
    setNewFolderName(folderName) {
      this.newFolderName = folderName;
    },
    isRouteActive(routeName) {
      return this.$route.name === routeName;
    },
    isFolderRouteActive(folder) {
      return this.$route.path.includes(`/folder/${folder._id}`);
    },
    openExternalPage(linkItem) {
      window.open(
        Utils.getExternalLinkByLang({
          linkEn: linkItem.linkEn,
          linkIt: linkItem.linkIt,
        }),
        "_blank"
      );
    },
    openRenameFolderModal(folder) {
      this.selectedFolder = folder;
      this.folderRenameModalStatus = true;
    },
    openDeleteFolderModal(folder) {
      this.selectedFolder = folder;
      this.folderDeleteModalStatus = true;
    },
    renameFolder() {
      if (!this.selectedFolder) return;
      this.selectedFolder.name = this.newFolderName; // hack FIX IT
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("folder/updateFolderByClientId", this.selectedFolder)
        .then(() => {
          this.$store.dispatch("folder/retrieveFoldersByClientId");
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    deleteFolder() {
      if (!this.selectedFolder) return;
      const folderId = this.selectedFolder._id;
      this.$store.commit("loader/SET_LOADER", {});
      this.$store
        .dispatch("folder/deleteFolderByClientId", folderId)
        .then(() => {
          this.$store.dispatch("folder/retrieveFoldersByClientId");
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
  },
};
</script>

<style scoped>
.collapsed-menu {
  transition: width 0.5s ease;
  /* position: fixed; */
  /* height: 100vh; */
  z-index: 3;
  width: 60px;
  overflow-x: hidden;
  overflow-y: auto;
}

.expanded-menu {
  transition: width 0.5s ease;
  width: 300px;
  border-right: 4px solid #2d3648;
}

.bg-gray-primary {
  background-color: #fff;
}

.list-group-item {
  background-color: #fff;
}

.menu__text-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #717d96;
  margin-left: 30px;
}
.menu__item-container {
  display: flex;
  align-items: center;
}
.text-hide-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
}

.menu__text__active {
  color: #2d3648;
  font-weight: 700;
}

.bg-color-light {
  background-color: #fff;
}

.menu__icon {
  color: #2d3648;
  width: 16px;
}

/* .active__menu-icon {
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
} */

.margin__t_3 {
  margin-top: 34px;
}

.emotiva__pink__background {
  background-color: #f2e7fe;
}
</style>
